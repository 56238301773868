import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ckLogo from '../../lib/images/ck-nav-logo-2022.svg';

const coBrandedLogo = (merchant) => {
  return `https://creditkey-assets.s3-us-west-2.amazonaws.com/co_brands/${merchant.public_key}-ck-logo.svg`;
};

export default function Logo({ merchant }) {
  const [logo, setLogo] = useState(ckLogo);

  useEffect(() => {
    let isMounted = true;
    if (merchant && logo !== coBrandedLogo(merchant)) {
      axios
        .create()
        .get(coBrandedLogo(merchant))
        .then(() => {
          if (isMounted) setLogo(coBrandedLogo(merchant))
        })
        .catch(() => {
          if (isMounted) setLogo(ckLogo)
        });
    }
    return () => {
      isMounted = false;
    };
  }, [merchant]);

  return <img src={logo} className="w-3/5 sm:w-3/4" />;
}
